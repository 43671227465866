export enum SiteId {
  ZIGZAG = '1',
  POSTY = '2',
  FBK = '3',
}

export enum SiteHost {
  ZIGZAG = 'zigzag.kr',
  POSTY = 'posty.kr',
  FBK = 'fashionbykakao.com',
}

interface RequestContext {
  headers: {
    host?: string;
  };
}

/**
 * 주어진 호스트로부터 사이트 ID를 가져옵니다.
 * @param context - 요청 컨텍스트 객체 (선택 사항)
 * @returns 사이트 ID
 */
export function getSiteIdFromHost(context?: RequestContext): SiteId {
  const host = typeof window !== 'undefined' ? window.location.host : context?.headers.host ?? '';

  if (host.includes(SiteHost.ZIGZAG)) {
    return SiteId.ZIGZAG;
  } else if (host.includes(SiteHost.POSTY)) {
    return SiteId.POSTY;
  } else if (host.includes(SiteHost.FBK)) {
    return SiteId.FBK;
  }

  // 식별이 안될 경우 기본값으로 지그재그 반환
  return SiteId.ZIGZAG;
}

export function getSiteNameFromHost(context?: RequestContext): string {
  const site_id = getSiteIdFromHost(context);

  switch (site_id) {
    case SiteId.ZIGZAG:
      return '지그재그';
    case SiteId.POSTY:
      return '포스티';
    case SiteId.FBK:
      return '패션바이카카오';
    default:
      return `Unknown Site ID: ${site_id}`;
  }
}
